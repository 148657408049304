@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;800&display=swap');

:root {
  --vulcan-primary-color: #a41d36;
}

html, body, #root {
  width: 100%;
  height: 100%;
}

body {
  background-color: #F6F6F6 !important;
  font-family: 'Nunito Sans', sans-serif;
}
